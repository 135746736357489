.text-nombre-message-forum {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	font-size: 32px;
	line-height: 39px;
	color: #5e5b5b;
	margin-bottom: 0;
	text-align: center;
}
.text-notif-type-message-forum {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	line-height: 15px;
	color: #5e5b5b;
	margin-bottom: 0;
	text-align: center;
}
.img-pp-forum {
	width: 38px;
	height: 38px;
	border-radius: 50%;
}
.btn-delete-forum {
	color: #5e5b5b;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #ffffff;
	border: 1px solid #049471;
	border-radius: 50%;
	padding: 0;
	width: 50px;
	height: 50px;
}
.name-text-profil-forum {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #5e5b5b;
}
.admin-message-forum-text,
.admin-message-forum-text p {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 25px;
	color: #5e5b5b;
	margin-bottom: 5px;
}
.img-pp-forum-detail {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}
.forum-title {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #049471;
}
.forum-created_at {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 17px;
	color: rgba(0, 0, 0, 0.8);
	margin: 0;
}
.hover__underline:hover {
	text-decoration: underline;
	text-underline-offset: 3px;
}
.responses-messages-forum {
	height: 370px;
	overflow-y: scroll;
}
.responses-messages-forum::-webkit-scrollbar {
	width: 6px;
}

.responses-messages-forum::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}

/* Handle */
.responses-messages-forum::-webkit-scrollbar-thumb {
	background: #049471;
	border-radius: 10px;
}

/* Handle on hover */
.responses-messages-forum::-webkit-scrollbar-thumb:hover {
	background: #049471;
}
