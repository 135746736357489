.paginations-container .page-item {
	margin-right: 6px;
}
.paginations-container a.page-link {
	color: var(--gray-500, #667085);
	text-align: center;
	font-family: Nunito;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	margin-left: 3px;
	border-radius: 0 !important;
	border: none;
	box-shadow: none !important;
}
.paginations-container .pagination .active > .page-link {
	border-radius: 8px;
	background: #edfffb;
	color: #08bb90 !important;
}
