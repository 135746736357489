.text-prix-abonnement {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 32px;
  color: #5e5b5b;
}
.text-list-detail-abonnement {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15.7558px;
  line-height: 203.9%;
  color: #000000;
}
