.nav-items-custom {
  margin-bottom: 20px;
}
.nav-link-vertival-custom {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: #ffffff;
  position: relative;
}

.nav-link-vertival-custom:hover {
  background: #08bb90;
  color: #fff !important;
  transition: 0.2s ease;
  border-radius: 16px;
}
.nav-link-vertival-custom.active {
  background: #08bb90;
  color: #fff !important;
  border-radius: 10px;
}

.nav-link-vertival-custom.active .svg {
  color: #fff !important;
}
.nav-link-vertival-custom:hover .icon-navbar-vertical {
  color: #fff;
}
.nav-link-vertival-custom svg {
  font-size: 20px;
  margin-right: 15px;
  color: #fff !important;
}
.hoverable:hover span.hiddenable-navlink-label {
  display: inline-block;
}
.navbar-vertical-customisation:hover span.hiddenable-navlink-label {
  display: inline-block;
}

.hiddenable-navlink-label {
  padding-left: 10px;
}
.navbar-vertical-customisation {
  padding: 75px 0 40px 0;
  height: 100%;
  max-width: 25%;
  background-color: #1468cc;
  position: fixed;
  left: 0;
  right: 0;
  top: 90px;
  z-index: 1031;
  width: max-content;
}

.navbar-vertical-customisation::-webkit-scrollbar {
  width: 3px;
  height: 100%;
}
.navbar-vertical-customisation::-webkit-scrollbar-track {
  background: transparent;
}
.navbar-vertical-customisation::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
  border: 3px solid transparent;
}
.nav-item-vertical-custom {
  padding: 10px 25px;
  margin-bottom: 5px;
}
#scrollbar {
  height: 100%;
  overflow-y: auto;
}
#scrollbar::-webkit-scrollbar {
  display: none;
}
.content {
  height: 350px;
}
.collapsed ~ .panel-body {
  padding: 0;
}
.arrow-collapse-costom.collapsed .fa-chevron-down,
.arrow-collapse-costom .fa-chevron-right {
  display: none;
}

.arrow-collapse-costom.collapsed .fa-chevron-right,
.arrow-collapse-costom .fa-chevron-down {
  display: inline-block;
}

i.fa {
  cursor: pointer;
  margin-left: 20px;
}
.card-body-collapsed-sidebar {
  background: transparent;
  border: none;
}
.list-style-none {
  list-style: none;
}
.nav-link:focus,
.nav-link:hover {
  color: inherit;
}
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none !important;
}
.nav-link-vertival-custom-collapsed {
  display: none;
}
.hoverable:hover .nav-link-vertival-custom-collapsed {
  display: inline-block;
}
.arrow-collapse-costom {
  color: #ffffff !important;
}
.info-user-connect-navmenu {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.img-user-connect-navmenu {
  width: 40px;
  height: 40px;
  border-radius: 10px;
}
.container-info-user-connect-navmenu {
  display: none;
}
.container-user-connect-info {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hoverable:hover .container-info-user-connect-navmenu {
  display: flex;
}
.ligne-logo {
  background: #158a9182;
  width: 1px;
}
.btn-logout {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ff4842;
  padding: 10px;
}
.btn-logout:hover {
  background: #ff4842;
  color: #fff;
}
.container-btn-logout {
  display: none;
}
.hoverable:hover .container-btn-logout {
  display: inline-block;
  padding: 10px 35px;
}
.menu-mobile__admin {
	position: absolute;
	top: 0;
	left: 0;
	background-color: #1468cc;
	min-height: 30vh;
	z-index: 1000;
}
.version-view-container {
  color: #fff;
  text-align: center;
  margin-top: 3rem;
  font-size: 20px;
}
