.join-us-section {
	background: #fff;
	padding: 80px 8%;
}
.title-col-left-join-us {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 33px;
	color: #000000;
}
.content-category-list {
	padding-left: 0;
	padding-top: 30px;
}
.list-category-item {
	list-style: none;
	padding: 15px 0;
}
.btn-category-list,
.btn-category-list:hover {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 25px;
	color: #000000;
	padding: 5px 15px;
}
.btn-category-list.active {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 25px;
	color: #08bb90;
	border-left: 5px solid #08bb90 !important;
	border: 0;
	border-radius: 0;
}
.category-container-item {
	background: #ffffff;
	border: 0.918188px solid #08bb90;
	border-radius: 4.59094px;
	padding: 20px;
}
.title-category-item-join-us {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 27px;
	color: #000000;
}
.text-category-item-join-us {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 131.7%;
	color: rgba(0, 0, 0, 0.65);
}
.title-category-detail {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	line-height: 132.5%;
	color: #000000;
}
.share-info-container {
	display: flex;
	gap: 25px;
	align-items: center;
}
.text-share-info {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 28px;
	text-align: justify;
	color: rgba(0, 0, 0, 0.75);
}
.social-media-share:hover {
	transform: scale(1);
}
.social-media-share {
	transform: scale(0.7);
}
.text-category-detail {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 29px;
	letter-spacing: 0.01em;
	color: #5c727d;
}
.btn-postuler {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	color: #ffffff;
	background: #08bb90;
	border-radius: 5px;
	padding: 15px 30px;
}
.btn-postuler:hover {
	color: #08bb90;
	font-weight: 700;
	border: 1px solid #08bb90;
	background: #fff;
}
.text-date-others-category {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #303030;
}
.container-category-detail-col-right {
	border: 1px solid #cdd1dd;
	border-radius: 5px;
	padding: 15px;
}
.category-detail-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #303030;
}
.modal-title {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 600;
	font-size: 30px;
	line-height: 51px;
	letter-spacing: -1px;
	color: #1d293f;
}
.ReactModal__Overlay--after-open {
	background-color: rgb(135 125 125 / 75%) !important;
	z-index: 9999;
}
.form-label-modal {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 20px;
	color: #0c0b0b;
}
.form-control-modal {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	font-size: 13px;
	line-height: 20px;
	color: #8b8b8b;
	border: 0.5px solid #1468cc;
	border-radius: 10px;
	padding: 15px;
}
.container-section-join-us-page {
	padding: 3%;
}
.joinustitle {
	color: #000;
	font-family: Nunito;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 29.238px; /* 182.735% */
	letter-spacing: 0.16px;
}
.joinus-offer-card {
	border: 1px solid rgba(0, 0, 0, 0.2);
	background: #fff;
	box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.09);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
a.joinus-offer-card,
a.joinus-offer-card:hover {
	text-decoration: none;
}
.joinus-offer-card h3,
.detail-poste h1 {
	color: #000;
	font-family: Nunito;
	font-size: clamp(20px, 3vw, 26px);
	font-style: normal;
	font-weight: 600;
	line-height: 24px; /* 92.308% */
	letter-spacing: 0.1px;
}
.joinus-offer-card p,
.detail-poste-text,
.detail-poste-text p,
.detail-poste p {
	color: #000;
	font-family: Open Sans;
	font-size: clamp(14px, 3vw, 16px);
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 150% */
	letter-spacing: 0.1px;
}
.btn-postuler {
	border-radius: 7px;
	background: #08bb90;
	border: 1px solid #08bb90;
	font-size: 14px;
	padding: 14px 40px !important;
}
.modal.fade.show{
	background-color: rgba(0, 0, 0, 0.5) !important;  
}