.about-us-component {
	background: #fff;
	overflow-x: hidden !important;
}
.container-text-describe-kiwu-abot-us {
	background: #ffffff;
	border-bottom: 1px solid #08bb90;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
	padding: 20px;
	width: 70%;
	margin: 50px auto;
	border-radius: 10px;
}
.joinus-container {
	width: 70%;
	margin: auto;
}
.joinus-title {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 34px;
	text-align: justify;
	letter-spacing: 0.01em;
	color: #fff;
}
.text-describe-about-kiwu {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 26px;
	line-height: 38px;
	text-align: center;
	letter-spacing: 0.01em;
	color: #5d6163;
}
.title-section-about-kiwu {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 33px;
	line-height: 45px;
	color: #ffffff;
}
.text-section-about-kiwu {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 29px;
	letter-spacing: 0.01em;
	color: #ffffff;
	text-align: justify;
}
.content-section-page-about {
	padding: 70px 8%;
}
.content-section-page-about2 {
	padding: 60px 8%;
}

.col-left-how-it-work-kiwu {
	background: #209e71;
	padding: 30px 30px 30px 8%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.title-section-how-it-work-kiwu {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 41px;
	line-height: 51px;
	color: #ffffff;
}
.text-how-it-work-section {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 29px;
	color: #ffffff;
	text-align: justify;
}
.img-how-it-work-section {
	width: 100%;
}
.how-it-work-section {
	padding-bottom: 100px;
}
