.landing-page-container {
	background: #fff;
}
.section-banner-landing-page {
	overflow: hidden;
	background-repeat: no-repeat;
	background-size: cover !important;
	background-position: 50%;
	width: 100%;
	height: 75vh;
	position: relative;
	background: linear-gradient(
			113.15deg,
			rgba(8, 187, 144, 0.85) 15.1%,
			rgba(20, 104, 204, 0.85) 126.53%
		),
		url(../../assets/landingpage/banner.png);
}
.section-banner-landing-page::before {
	content: "";
	position: absolute;
	width: 200%;
	height: 100%;
	left: 60%;
	top: 77%;
	background-color: #fff;
	transform: translateX(-54%) skew(-60deg, -6deg);
	border-radius: 0;
}
.empty-fluidy-landing-page {
	padding: 20px 140px;
}
.crypta-container {
	padding: 0 1rem;
	margin: auto;
}
.landing-page-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.logo-container-landing-page {
	margin-top: 3%;
}
.navbar-nav-header-landing-page {
	flex-direction: row;
}
.nav-item-li {
	padding: 0 70px;
}
.nav-link-linkv1 {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	line-height: 28px;
	text-align: center;
	letter-spacing: -0.275862px;
	color: #ffffff !important;
	white-space: nowrap;
}
.nav-link-linkv1:hover {
	color: #ffffff !important;
}
.title-landingpage {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 59px;
	line-height: 64px;
	letter-spacing: -1px;
	color: #ffffff;
}
.text-chrono-landingpage {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 64px;
	letter-spacing: -1px;
	color: #ffffff;
}
.container-banner-landingpage {
	padding-top: 30px;
}
/* .content-section-about-crypta {
	padding-top: 50px;
	padding-bottom: 100px;
} */
.section-about-crypta {
	z-index: 9;
	position: relative;
	background: #fff;
}
.landingpage-crypta-container {
	padding: 0 140px;
}
.content-text-card-item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.title-crypta-landingpage {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: clamp(24px, 3vw, 41px);
	line-height: clamp(28px, 3vw, 51px);
	color: #000000;
	text-align: center;
}
.text-describe-about-section {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 25px;
	color: #39393a;
}
.title-card-item-section-about {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 900;
	font-size: 21px;
	line-height: 29px;
	text-align: center;
	text-transform: uppercase;
	color: #ffffff;
}
.text-describe-card-item-section-about {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 21px;
	text-align: justify;
	color: #fafafa;
}
.content-img-card-item {
	display: flex;
	align-items: center;
}
.card-section-about-item {
	padding: 15px 30px;
	display: flex;
	justify-content: space-between;
	box-shadow: 0px 5.5347px 11.8601px rgba(0, 0, 0, 0.15);
	border-radius: 7.90672px;
	width: 100%;
	margin-bottom: 15px;
}
.color1-card-item {
	background: radial-gradient(93.88% 579.95% at 3.79% 50%, #08bb90 0%, #08bb90 100%);
}
.container-card-secrtion-about-us {
	display: flex;
	justify-content: space-between;
	gap: 10px;
}
.color2-card-item {
	background: #1190c6;
}
.img-card-item-about-section {
	width: 75.9px;
	height: 75.9px;
}
.color3-card-item {
	background: #0a9ba5;
}
.color4-card-item {
	background: #397ed0;
}
/* .row-about-section {
	padding-bottom: 150px;
} */
.img-capture-about-section {
	width: 100%;
}
/* .abonnement-section {
	padding: 100px 0;
} */
.abonnement-content {
	display: flex;
	justify-content: space-between;
	gap: 20px;
}
.abonnement-item {
	background: #ffffff;
	box-shadow: -1px 6px 19px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
	padding: 20px;
	position: relative;
	height: 100%;
}
.abonnement-check-list-item-btn-container {
	position: absolute;
	left: 40%;
	transform: translateX(-30%);
	bottom: 45px;
}
.container-abonnement-check-list {
	padding-bottom: 90px;
}
.title-abonnement {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 28px;
	text-align: center;
}
.span-abonnement {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 800;
	font-size: 11px;
	line-height: 26px;
	text-align: center;
	text-transform: uppercase;
	color: #ffffff;
}
.content-span-abonnement {
	border-radius: 7px;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1.5rem;
}
.abonnement-check-list-item {
	display: flex;
	gap: 15px;
	margin-bottom: 25px;
	align-items: center;
}
.text-check-list,
.text-check-list li {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 150%;
	color: #000000;
}
.advantages-offer li {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 150%;
	color: #000000;
	list-style-image: url("../../assets/landingpage/check.png");
	margin-bottom: 1.5rem;
	padding-left: 10px;
	position: relative;  
}
.advantages-offer li.non-dispo {
	list-style-image: url("../../assets/landingpage/check2.png");
	margin-bottom: 1.5rem;
}
.details-offer li{
	color: #5A5A5A !important;
}
.abonnement-bc1 {
	border-top: 7px solid #6f9ae7;
}
.abonnement-color1 {
	color: #6f9ae7;
}
.bg-span-abonnement1 {
	background: #6f9ae7;
}
.bg-span-abonnement1:hover {
	background: #6f9ae7;
	/* color: #6f9ae7 !important; */
}
.text-check-list-last {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 203.9%;
	color: rgba(0, 0, 0, 0.4);
}
.abonnement-bc2 {
	border-top: 7px solid #ff8d95;
}
.abonnement-color2 {
	color: #ff8d95;
}
.bg-span-abonnement2 {
	background: #ff8d95;
}
.bg-span-abonnement2:hover {
	background: #ff8d95;
	/* color: #ff8d95 !important; */
}
.abonnement-bc5 {
	border-top: 7px solid #a77e3d;
}
.abonnement-color5 {
	color: #a77e3d;
}
.bg-span-abonnement5 {
	background: #a77e3d;
}
.bg-span-abonnement5:hover {
	background: #a77e3d;
	/* color: #a77e3d !important; */
}
.abonnement-bc4 {
	border-top: 7px solid #e3bb2c;
}
.abonnement-color4 {
	color: #e3bb2c !important;
}
.bg-span-abonnement4 {
	background: #e3bb2c;
}
.bg-span-abonnement4:hover {
	background: #e3bb2c;
	/* color: #e3bb2c !important; */
}
.abonnement-bc3 {
	border-top: 7px solid #379bb1;
}
.abonnement-color3 {
	color: #379bb1;
}
.bg-span-abonnement3 {
	background: #379bb1;
}
.bg-span-abonnement3:hover {
	background: #379bb1;
	/* color: #379bb1 !important; */
}
.content-text-testimonial-landingpage {
	background: #f7f7f7;
	padding: 20px;
}
.text-testimonial-landingpage {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 28px;
	color: #1d293f;
}
.content-info-testiminial {
	display: flex;
	gap: 20px;
	align-items: center;
}
.photo-profil-testimonial {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}
.info-user-testimonial {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 32px;
	color: #1d293f;
}
.fonction-user-info-testimonial {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 11px;
	line-height: 28px;
	color: #1d293f;
}
.content-testimonal-landingpage {
	display: flex;
	justify-content: space-between;
	gap: 20px;
}
.react-rater-star {
	color: #d9d9d9;
}
.react-rater-star.is-active {
	color: #08bb90 !important;
}
.text-chrono {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 49px;
	line-height: 11px;
	letter-spacing: -1px;
	color: #ffffff;
	text-align: center;
}
.libelle-text-chrono {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 64px;
	text-align: center;
	letter-spacing: 5px;
	text-transform: uppercase;
	color: #ffffff;
}
.container-timer-landingpage {
	display: flex;
	gap: 15px;
	align-items: center;
}
.line-chrono {
	width: 3px;
	height: 50px;
	background: #fff;
	border-radius: 60%;
	margin-bottom: 20px;
}
.container-coolapse-questions .card {
	border-radius: 8px;
	background: rgba(255, 255, 255, 0.18);
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
	padding: 20px;
	border: 0;
}
.card__header {
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	align-items: center;
	width: 100%;
}
.card__indicator {
	height: 12px;
	width: 12px;
	position: relative;
}
.card__indicator::before {
	transition: transform 0.3s ease;
	border-style: solid;
	border-width: 2px 2px 0 0;
	border-color: #7c8087 !important;
	content: "";
	display: inline-block;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%) rotate(45deg);
}
.card[open] > .card__header > .card__indicator::before {
	transform: translateY(-60%) rotate(135deg);
}
.card__body {
	padding: 40px 0;
}
.card__body p {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 140%;
	color: rgba(13, 13, 14, 0.85);
	text-align: justify;
}
.title-collapse-question, .title-collapse-section {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 26px;
	color: #070707;
	width: 96%;
}
.title-collapse-section {
	font-weight: 500 !important;
	font-size: 18px;
}
.content-col-right-faq-landingpage {
	display: flex;
	justify-content: center;
}
.bg-gradient-theme {
	background: linear-gradient(
			91.92deg,
			rgba(34, 197, 128, 0.8) -6.79%,
			rgba(33, 137, 182, 0.8) 96.28%
		),
		linear-gradient(158.83deg, #08bb90 19.09%, #1468cc 114.69%);
}
.section-info-download-app {
	background: linear-gradient(
			91.92deg,
			rgba(34, 197, 128, 0.8) -6.79%,
			rgba(33, 137, 182, 0.8) 96.28%
		),
		linear-gradient(158.83deg, #08bb90 19.09%, #1468cc 114.69%);
	padding: 50px 0;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
}
.info-download-app-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.title-info-download-app {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 51px;
	line-height: 51px;
	letter-spacing: -1px;
	color: #ffffff;
}
.container-img-download-app {
	display: flex;
	align-items: center;
	gap: 25px;
}
.navbar-toggler-icon {
	background-image: none !important;
}
.navbar-toggler {
	border: 1px solid transparent !important;
}
.bg-header-kiwu {
	background: #fff;
}
.nav-link-linkv2,
.nav-link-linkv2:hover {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	line-height: 28px;
	text-align: center;
	letter-spacing: -0.275862px;
	color: #101010 !important;
}
.nav-link-linkv2.active {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	line-height: 28px;
	text-align: center;
	letter-spacing: -0.275862px;
	color: #1468cc !important;
}
.logo-lp-header {
	width: 60%;
	/* width: 124.84px;
  height: 70.17px; */
	/* width: 103px;
  height: 101.07px; */
}
.img-content-col-right-banner-lp {
	z-index: 9999;
	position: relative;
	right: 0;
	height: 70vh;
}

.alert-kiwu-container {
	border-left: 6px solid #08bb90;
	border-radius: 0.4rem;
	color: #000;
	display: flex;
	align-items: center;
	gap: 15px;
	margin-top: 5px;
	width: 100%;
	background-color: rgba(34, 197, 129, 0.325);
}
.alert-kiwu-container svg {
	font-size: 34px;
	animation: animate 6s linear infinite;
}
.text-alert-kiwu {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 22px;
	line-height: 24px;
	letter-spacing: -1px;
	color: #000;
}
@keyframes animate {
	0% {
		transform: rotateX(-20deg) rotateY(-360deg);
	}
	100% {
		transform: rotateX(-20deg) rotateY(0deg);
	}
}
.btn-scroll-top {
	position: fixed;
	right: 20px;
	bottom: 20px;
	z-index: 9999;
	cursor: pointer;
	color: #fff;
	background: #08bb90;
	font-size: 22px;
	display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
}
.btn-scroll-top:hover {
	background: #1468cc;
	color: #fff;
}
.example-enter {
	opacity: 0.01;
}

.example-enter.example-enter-active {
	opacity: 1;
	transition: opacity 4ms ease-in;
}

.example-leave {
	opacity: 1;
}

.example-leave.example-leave-active {
	opacity: 0.01;
	transition: opacity 3ms ease-in;
}

.gradient-background {
	height: 100vh;
	background: linear-gradient(62deg, #1468cc, #08bb90, #23a6d5, #23d5ab);
	animation: gradient 15s ease infinite;
	background-size: 400% 400%;
	overflow: hidden;
}
@-webkit-keyframes gradient {
	0% {
		background-position: 0 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
@keyframes gradient {
	0% {
		background-position: 0 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.newsletter {
	display: flex;
	background-color: #1467ccc1;
	width: 70%;
	padding: 4rem;
	margin: auto;
	margin-top: 20vh; /* this margin is for this visualization */
}

.newsText {
	color: white;
	width: 40%;
	margin: auto;
}

.newsText h1 {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 35px;
	line-height: 40px;
	letter-spacing: -1px;
	color: #ffffff;
}
.newsText p {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 30px;
	letter-spacing: 5px;
	text-transform: uppercase;
	color: #ffffff;
}
.newsText img {
	width: 90%;
	margin-top: 1rem;
}

.newsSign {
	background-color: #f1f1f6;
	/* width: 25%; */
	margin: auto;
	padding: 2rem 4rem;
}

form {
	display: flex;
	flex-direction: column;
	text-align: left;
}

.input-field {
	position: relative;
	margin-bottom: 1rem;
}
.form-label-coming-soon {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 25px;
	color: #000;
}
.input-newsletter-coming-soon {
	background-color: #fff;
	color: rgba(0, 0, 0, 0.7) !important;
	border: 1px solid #fff !important;
	font-family: "Nunito" !important;
	font-style: normal !important;
	font-weight: 400;
	font-size: 16px !important;
	line-height: 25px;
	width: 100%;
	border-radius: 5px !important;
	padding: 10px !important;
}

.input-newsletter-coming-soon:focus {
	outline: none;
	color: rgba(0, 0, 0, 0.6);
	border-bottom: 1px solid #397ed0;
}

.btn-newletter-coming-soon {
	background: linear-gradient(
		113.15deg,
		rgba(8, 187, 144, 0.85) 15.1%,
		rgba(20, 104, 204, 0.85) 126.53%
	);
	border-radius: 5px;
	color: #fff;
	font-size: 18px !important;
	font-weight: 600;
	width: 100%;
	padding: 10px !important;
	margin: auto;
	border: 0;
	border-radius: 5px !important;
	transform: all 0.3s;
}
.btn-newletter-coming-soon:hover {
	background: #fff;
	color: rgba(20, 104, 204, 0.85);
	border: 1px solid rgba(20, 104, 204, 0.85);
}
.abonnement-check-list-item-pre-commande {
	border-radius: 7px;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 800;
	font-size: 11px;
	line-height: 26px;
	text-align: center;
	text-transform: uppercase;
}
.item-pre-btn-commande1 {
	border: 1px solid #6f9ae7;
	color: #6f9ae7;
}
.item-pre-btn-commande1:hover {
	background: #6f9ae7;
	color: #fff;
}
.item-pre-btn-commande2 {
	border: 1px solid #ff8d95;
	color: #ff8d95;
}
.item-pre-btn-commande2:hover {
	background: #ff8d95;
	color: #fff;
}
.item-pre-btn-commande5 {
	border: 1px solid #a77e3d;
	color: #a77e3d;
}
.item-pre-btn-commande5:hover {
	background: #a77e3d;
	color: #fff;
}
.item-pre-btn-commande4 {
	border: 1px solid #e3bb2c;
	color: #e3bb2c;
}
.item-pre-btn-commande4:hover {
	background: #e3bb2c;
	color: #fff;
}
.item-pre-btn-commande3 {
	border: 1px solid #379bb1;
	color: #379bb1;
}
.item-pre-btn-commande3:hover {
	background: #379bb1;
	color: #fff;
}
.btn-payer-pre-commande {
	border-radius: 7px;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 800;
	font-size: 11px;
	line-height: 26px;
	text-align: center;
	text-transform: uppercase;
}
/* .PreCommandeModal-text-paragraphe {

} */
.btn-payer-pre-commande-container {
	display: flex;
	justify-content: flex-end;
}
.btn-payer-pre-commande {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	color: #ffffff;
	background: #08bb90;
	border-radius: 5px;
	padding: 15px 30px;
	margin: initial;
}
.modal-title-admin {
	display: flex;
	align-items: center;
	gap: 15px;
}

.swal-message-style {
	font-size: 18px;
}
.custom-text-check-list-mise-en-avant {
	font-weight: 700;
}
.text-warning-pre-commande li {
	font-size: 12px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	line-height: 144%;
	color: #535353;
}
.text-warning-pre-commande-attention1 {
	color: #6f9ae7;
	font-weight: 500;
}
.text-warning-pre-commande-attention2 {
	color: #ff8d95;
	font-weight: 500;
}
.text-warning-pre-commande-attention5 {
	color: #a77e3d;
	font-weight: 500;
}
.text-warning-pre-commande-attention4 {
	color: #e3bb2c;
	font-weight: 500;
}
.text-warning-pre-commande-attention3 {
	color: #379bb1;
	font-weight: 500;
}

.PreCommandeModal-text-paragraphe .text-check-list {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 150%;
	color: #5a5a5a;
}
.PreCommandeModal-text-paragraphe .text-check-list-last {
	text-decoration: line-through;
	color: #000000;
	font-size: 12px;
}
.container-abonnement-check-lists-titre-detail {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 18.3828px;
	line-height: 203.9%;
	color: #5a5a5a;
}
.modal-header-kivu-titre-confirm-pre-order {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
}
.custom-modal-header-border {
	border-bottom: 0.76px solid #d9d9d9;
}
.custom-resum-preorder-infos-user {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 12.2552px;
	line-height: 203.9%;
	color: #5a5a5a;
}
.custom-resum-preorder-infos-user span {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 203.9%;
	color: #5a5a5a;
}
.custom-container-abonnement-check-lists .abonnement-check-list-item {
	margin-bottom: 12px;
}
.custom-modal-confirm .content-span-abonnement {
	margin: 0px;
	padding: 5px 10px;
}
.custom-modal-confirm .btn-payer-pre-commande {
	padding: 10px 20px;
}
.conf-pre-order-label-cgu {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 12.226px;
	line-height: 18px;
	color: #555555;
	width: 80%;
}
.conf-pre-order-link-cgu {
	color: #08bb90;
	text-decoration: none;
}

.btn-payer-pre-commande-container .form-check-input:checked {
	background-color: #08bb90;
	border-color: #08bb90;
}
.resume-infos-container {
	border-top: 0.76px solid #d9d9d9;
	border-bottom: 0.76px solid #d9d9d9;
	padding: 8px 0px;
}
.custom-modal-confirm .modal-title-admin {
	font-size: 20px;
}

.bg-span-abonnement-annuler span {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 12.226px;
	line-height: 203.9%;
	color: #5a5a5a;
}

.content-img-download-app-title {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 32px;
	letter-spacing: -1px;
	color: #ffffff;
}
.content-img-download-app-title-black {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 32px;
	letter-spacing: -1px;
	color: #252525;
}
.content-img-download-app-item-container {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
}

.container-element-download-app {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
}
.row-footer {
	display: flex;
	align-items: center;
}

.btn-kivu-custom-size {
	font-size: 7px;
	padding: 7px;
	color: rgba(0, 0, 0, 0.5) !important;
}
.btn-kivu-custom-size:disabled {
	cursor: not-allowed !important;
}
.img-tel-faq-landingpage {
	width: 80%;
}
.menu-mobile {
	position: absolute;
	top: 0;
	right: 0;
	background-color: #1468cc;
	min-height: 30vh;
	z-index: 1000;
}
.menu-mobile li {
	list-style: none;
}
.menu-mobile-list {
	padding-right: 2rem;
}
.section-faqs {
	margin-bottom: 0rem !important;
}
.mode-paiement-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
}
.mode-paiement-item img{
	width: 150px;
	height: 100px;
}
.mode-paiement-item input {
	width: 20px;
	height: 20px;
}