.nav-item-setting {
	position: relative;
}
.nav-link-setting {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 120%;
	color: #1468cc !important;
}
.nav-link-setting.active {
	border-color: transparent !important;
	font-weight: 600;
}
.nav-link-setting.active::after {
	background: #12c28d;
	bottom: 0;
	content: "";
	height: 4px;
	left: 0;
	margin: auto;
	position: absolute;
	right: 6px;
	width: 39px;
}
.nav-link-setting:hover {
	border-color: transparent !important;
}
.nav-tabs-setting {
	border-bottom: transparent;
}
.title-termes-conditions {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 25px;
	line-height: 30px;
	color: #06152b;
}
.text-conditions-termes,
.text-conditions-termes p {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 141.5%;
	color: #000000;
	text-align: justify;
}
.title-section-detail-admin {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 120%;
	letter-spacing: -0.5px;
	color: rgba(0, 0, 0, 0.22);
}
.form-detail-admin-profil {
	border-bottom: 1px solid #e9e9e9;
	border-top: transparent;
	border-left: transparent;
	border-right: transparent;
	padding: 15px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;

	color: #5a5a5a;
}
.notification-content {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 120%;
	color: #000000;
}
.notif-type {
	display: inline-block;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 120%;
	color: #fff !important;
	padding: 5px;
	border-radius: 5px;
	white-space: nowrap;
}
.bg-demande_paiement {
	background-color: #12c28d;
}
.bg-forum {
	background-color: #86ec84;
}
.bg-paiement {
	background-color: #1468cc;
}
.bg-dossier {
	background-color: #6065c6;
}
.bg-participant {
	background-color: #c2a875;
}
.bg-remboursement {
	background-color: #558e8a;
}
.bg-depot {
	background-color: #876500;
}
.bg-rdv {
	background-color: #7851d0;
}
.bg-reinitialisation_compteur {
	background-color: #c23255;
}
.gain-bloc {
	border-right: 2px solid #e9e9e9;
	min-height: 50vh;
}
.gain-bloc_title {
	font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    color: #11142d;
	margin-bottom: 2rem;
}

.gain-level_item{ 
	border-bottom: 1px solid #e9e9e9;
	padding-bottom: 2rem;
	margin: 2rem 0;
}
.gain-level_item:last-child{ 
	border-bottom: 0;
} 
.gain-form-check-input {
	width: 20px;
	height: 20px;
}
.gain-form-check-input:checked {
	background-color: #12c28d !important;
	border: 1px solid #12c28d;
}
.gain-form_label {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 30px;
	color: #1b1b1b;
	white-space: nowrap;
}

.screen_image__wrapper img {
	width: 3rem;
	height: 3rem;
}
.custon-btn-position {
	font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 12px !important;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.7) !important;
	background: none;
	border-radius: 100px;
	text-transform: uppercase;
	border: .2px solid #1468cc;
	text-align: center;
}