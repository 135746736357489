.footer-landingpage {
  background: #f7f7f7;
  padding: 30px 0;
}
.text-copyright {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #666666;
}
.contact-app-footer-item {
  display: flex;
  gap: 10px;
  align-items: stretch;
}

.text-contact-footer-item {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.291667px;
  color: #000000;
}
.wrapper-social-media {
  display: flex;
  justify-content: center;
  justify-content: space-evenly;
}
.button {
  width: 40px;
  height: 40px;
  cursor: pointer;
  background: #1e785af2;
  border-radius: 50%;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease 300ms;
}
a.button:hover {
  color: #fff;
  opacity: .9;
}

.button .icon i {
  font-size: 18px;
  line-height: 35px;
  transition: all 0.3s ease-in-out;
}

.footer-flex {
  display: flex;
  align-items: center;
}
