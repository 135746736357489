.chart-item-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
}
.form-select-date-graph {
    background: url(../../../../../assets/icons/Shape.png) 92% 47% / 13px no-repeat #fff !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.4px;
    color: #252733;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 15px;
    border: none;
}