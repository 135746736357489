.contact-us-section {
  padding: 50px 8%;
}
.content-col-left-contact-us-section {
  background: #08bb90;
  border: 1px solid #08bb90;
  border-radius: 5px;
  padding: 50px;
}
.container-info-contact-us-section-item {
  background: #10d7a7;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}
.title-text-info-contact {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  color: #ffffff;
  margin-bottom: 10px !important;
}
.text-value-info-contact-us {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 0 !important;
}
.content-col-right-contact-us-section {
  border: 1px solid #08bb90;
  border-radius: 10px;
  padding: 40px;
}
.content-col-right-contact-us-section-no-border {
  border: none;
}
.content-col-right-contact-us-section .ql-editor {
  background-color: #ffffff !important;
}