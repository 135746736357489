.auth-component {
  background: #ebf0f0;
}
.empty-header {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  padding: 50px;
}
.text-footer-login-page {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
}
.login-page-row {
  margin-top: 10%;
  border-radius: 10px;
  margin-bottom: 4%;
}
.right-side-col {
  background: #fff;
  border-radius: 10px;
}
.login-form-container {
  padding: 120px 60px;
}
.auth-right-side-container {
  padding: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth-form-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  line-height: 47px;
  text-align: center;
  color: #000000;
  font-size: clamp(30px, 1vw, 36px);
  white-space: nowrap;
}

.auth-form-group-login {
  width: 100%;
  background: #f7f7f7;
  border-radius: 10px;
}

.auth-form-control {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 15px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #444150;
}
.forget-password-link {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #08bb90;
  text-decoration: none;
}
.forget-password-link:hover {
  text-decoration: none;
  color: #08bb90;
  font-weight: bold;
}
.auth-form-message {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #333333;
}

.auth-submit-btn {
  background: #08bb90;
  width: 100%;
  padding: 15px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  text-align: center;
  border-radius: 4px;
}
.auth-submit-btn:hover {
  background: #fff;
  color: #08bb90;
  border: 1px solid #08bb90;
  text-decoration: none;
}
.auth-left-side-container {
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth-left-side-col {
  background: linear-gradient(90deg, #02b4c0 0%, #158a91 100%);
}

.auth-row.row .auth-col {
  padding: 0 !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.fixed-after-header {
  margin-top: 4%;
}
.form-label-login {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #444150;
}
