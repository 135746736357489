.empty-fluidy {
  padding: 20px 125px 20px 100px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
}
.alling-header {
  width: 100%;
}
.user-avatar {
  width: 37px;
  height: 35px;
  border-radius: 8px;
}
.headering-admin {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.logo-container.dashboard-header-logo img {
  width: 60px;
  height: 48px;
}
.header-others-items {
  display: flex;
  align-items: center;
}
.btn-header-param.active {
  border-color: transparent !important;
}
.container-menu-header-admin {
  display: flex;
  align-items: center;
}
.search-form-container {
  position: relative;
}
.navbar-header-trader {
  justify-content: space-around;
  width: 90%;
}
.user-name-connect {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #595a63;
}
.notification-counter {
  background: #eb0508;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 22px;
  color: #ffffff;
}
.user-acount-dashboard-header-display {
  display: flex;
  align-items: center;
}
.user-acount-dashboard-header {
  appearance: none;
  background: transparent;
  border: transparent;
}
.dropdown-toggle::after {
  display: none;
}
.sent-message-header-container .btn:hover {
  border: none;
  border-color: transparent;
}

.header-hiddenable-section.animated {
  animation: grow 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
.header-hiddenable-notification-section {
  position: absolute;
  right: 2%;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  padding: 10px;
  top: 81%;
  width: 50vw;
}

.header-hiddenable-notification-title {
  padding: 12px 17px;
  border-bottom: 1px solid #ececec;
  margin-left: -15px;
  margin-right: -15px;
  color: #000000;
  font-weight: 500;
  font-size: 12px;
}
.header-hiddenable-notification-li {
  list-style: none;
  padding: 7px 15px;
  border-bottom: 1px solid #ececec;
}
.header-hiddenable-notification-ul {
  padding-left: 0;
  margin-left: -10px;
  margin-right: -10px;
}
.header-hiddenable-notification-li p {
  font-size: 12px;
  color: #000000;
  font-weight: 500;
}
.header-hiddenable-notification-li:last-child {
  border-bottom: none;
}
.img-profil-notif {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.text-notif {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 99.2%;
  color: #000000;
}
.text-date-notif {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 99.2%;
  color: #000000;
}
.header-hiddenable-notification-li:hover .notification-item-link {
  text-decoration: none !important;
  color: #003da5 !important;
}
.notification-item-link {
  text-decoration: none;
}
.notification-item-link:visited,
.notification-item-link:active,
.notification-item-link:focus {
  text-decoration: none !important;
  /* color: #02A69C !important; */
}
.search-form-container {
  position: relative;
}
.search-form-content label {
  position: absolute;
  top: 17%;
  left: 4%;
  color: #bdbdbd;
  font-size: 20px;
}
input.form-control.search-input {
  width: 400px;
  border-radius: 10px;
  padding: 0.3rem 3rem !important;
  background: rgba(218, 218, 218, 0.59);
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 37px;
  color: #bdbdbd;
}

svg.search-icony {
  width: 20px;
  height: 20px;
}
.no-link,
.no-link:hover {
  text-decoration: none;
  color: inherit;
}
.dropdown-item-header-btn {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  color: #1a1a1a;
}
.add-item-content {
  background: #1468cc;
  padding: 10px;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-add-item-icon {
  color: #ffffff;
  font-size: 30px;
  font-weight: 600;
}
.logo-dashboard-admin {
  width: 50px;
  height: 60px;
}
.text-notif {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 99.2%;
  color: #000000;
}
.dropdown-menu-custom {
  left: -95px;
}
.dropdown-menu[data-bs-popper] {
  left: -95px !important;
}
.menu-button {
  background-color: transparent;
  border: none;
}