.carousel-produit-marketplace img.capture-mobile {
	width: 100%;
	height: 80vh;
}

.react-multi-carousel-track {
	/* gap: 15px; */
	padding: 60px 0;
}

/* .react-multiple-carousel__arrow--left {
	left: calc(1% + 1px) !important;
}
.react-multiple-carousel__arrow--right {
	right: calc(1% + 1px) !important;
} */
.react-multiple-carousel__arrow,
.swiper-button-prev,
.swiper-button-next {
	background: #1e785af2 !important;
	width: 50px !important;
	height: 50px !important;
	border-radius: 50%;
}

.react-multi-carousel-item, .swiper-slice {
	margin: 25px;
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease;
	cursor: pointer;
}

.react-multi-carousel-item:hover, .swiper-slice:hover {
	transform: scale(1.03);
	-webkit-transform: scale(1.03);
	-moz-transform: scale(1.03);
	-ms-transform: scale(1.03);
	-o-transform: scale(1.03);
	transform: scale(1.03);
	-webkit-backface-visibility: hidden;
}

.capture-mobile-img-modal {
	height: 100%;
	width: 100%;
	object-fit: contain;
}

.container-capture-mobile-img-modal {
	height: 600px;
	width: 100%;

}

.modal-capture-img-landingpage {
	top: 0 !important;
	bottom: 0;
	right: 0;
	background: rgba(128, 128, 128, 0.904);
}

.modal-backdrop {
	background-color: transparent !important;
	z-index: -1 !important;
}

.carousel-control-capture {
	background-color: #1e785af2;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	top: 50%;
}

.alice-carousel__stage-item, .horizontal-scroll__item {
	/* padding: 20px !important;
	margin: 10px !important; */
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease;
	/* cursor: pointer; */
}

.alice-carousel__stage-item:hover, .horizontal-scroll__item:hover {
	transform: scale(1.03);
	-webkit-transform: scale(1.03);
	-moz-transform: scale(1.03);
	-ms-transform: scale(1.03);
	-o-transform: scale(1.03);
	transform: scale(1.03);
	-webkit-backface-visibility: hidden;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
	position: absolute;
	width: 50px !important;
	height: 50px;
	border-radius: 50%;
	background-color: #1e785ad4 !important;
	color: #fff;
	top: 50%;
	display: flex !important;
	justify-content: center;
	align-items: center;
	padding: 0 !important;
}

.alice-carousel__prev-btn {
	left: 3%;
}

.alice-carousel__next-btn {
	right: 3%;
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
	color: #fff !important;
	font-size: 20px;
}

.carousel-produit-marketplace .modal-content {
	border: 0 !important;

}

.carousel-produit-marketplace .modal-header {
	border-bottom: 0 !important;
}

.carousel-produit-marketplace .btn-close {
	background: #e91927;
	color: #fff;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 !important;
}

.alice-carousel__wrapper {
	padding: 60px 0;
}

.carousel-produit-marketplace {
	/* padding: 60px 0; */
}

.react-multi-carousel-list {
	padding: 60px 0;
}

.react-multiple-carousel__arrow--right::before {
	content: "\003E" !important;
}

.react-multiple-carousel__arrow::before {
	font-family: inherit !important;
}

.react-multiple-carousel__arrow--left::before {
	content: "\003C" !important;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
	content: 'prev';
	font-size: 20px !important;
	color: #fff !important;
	display: block !important;
	/* font-family: revicons; */
	text-align: center !important;
	z-index: 2 !important;
	position: relative !important;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
	content: 'next';
	font-size: 20px !important;
	color: #fff !important;
	display: block !important;
	/* font-family: revicons; */
	text-align: center !important;
	z-index: 2 !important;
	position: relative !important;
}

.swiper-pagination {
	display: none !important;
}