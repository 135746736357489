.btn-export-csv {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.08));
  color: #000000;
  background: #ffffff;
  border-radius: 5px;
}
